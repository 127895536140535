import { createView, ViewComponent, ICreateViewArgs } from "./base";
import { PublicLayout } from "@layouts/Public";

export function createPublicView<
	TContext extends Record<string, unknown> = Record<string, unknown>,
	TComponent extends ViewComponent<any> = ViewComponent<any>,
>(component: TComponent, props: ICreateViewArgs<TContext, TComponent>) {
	return createView(component, {
		...props,
		layout: PublicLayout,
	});
}

export type { ViewComponent } from "./base";
