import React, { Fragment, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { Typography } from "~/components/Typography";
import type { IPublicTemplateProps } from "./types";

const useStyles = makeStyles((theme) => ({
	formColumn: {
		gridArea: "form",
		display: "flex",
		flexFlow: "column nowrap",
		justifyContent: "center",
		alignContent: "center",
		[theme.breakpoints.down(theme.breakpoints.values.md)]: {
			maxWidth: 480,
		},
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			marginTop: 50,
		},
	},
	textColumn: {
		gridArea: "text",
		minWidth: 380,
		maxWidth: 762,
		margin: "auto",
		color: theme.palette.text.contrast,
		[theme.breakpoints.down(theme.breakpoints.values.md)]: {
			display: "none",
		},
	},
	presentationTitle: {
		...theme.typography.presentationTitle,
		fontWeight: 500,
		marginBottom: 40,
	},
	presentationSubtitle: {
		...theme.typography.subtitle1,
		fontSize: 28,
		lineHeight: "38px",
	},
	logoPlaceholder: {
		gridArea: "logo",
	},
	contentContainer: {
		display: "grid",
		gridTemplateColumns: "1fr 480px",
		gridTemplateRows: "1fr",
		gridTemplateAreas: `
            "text form"
        `,
		minHeight: "100vh",
		padding: "53px 121px",
		[theme.breakpoints.down(theme.breakpoints.values.md)]: {
			display: "flex",
			padding: 24,
			justifyContent: "center",
		},
	},
}));

const PublicTemplate: React.FC<IPublicTemplateProps> = ({
	presentationTitle = [
		`Imagine a world where the customers and the dealers both win.`,
		`We did, and then we built it.`,
	],
	presentationSubtitle = "… it’s about more than engagement. It’s conversion.",
	formComponent,
}) => {
	const styles = useStyles();
	const largeText = useMemo(
		() =>
			Array.isArray(presentationTitle)
				? presentationTitle
				: [presentationTitle],
		[presentationTitle],
	);
	const smallText = useMemo(
		() =>
			Array.isArray(presentationSubtitle)
				? presentationSubtitle
				: [presentationSubtitle],
		[presentationSubtitle],
	);

	return (
		<div className={styles.contentContainer}>
			<div className={styles.logoPlaceholder} />

			<Box className={styles.textColumn} component="div">
				<Typography
					paragraph
					align="center"
					className={styles.presentationTitle}
				>
					{largeText.map((line, i) => (
						<Fragment key={i}>
							{line}
							{i < largeText.length && <br />}
						</Fragment>
					))}
				</Typography>
				<Typography
					paragraph
					align="center"
					className={styles.presentationSubtitle}
				>
					{smallText.map((line, i) => (
						<Fragment key={i}>
							{line}
							{i < smallText.length && <br />}
						</Fragment>
					))}
				</Typography>
			</Box>
			{formComponent && (
				<Box className={styles.formColumn}>{formComponent}</Box>
			)}
		</div>
	);
};

export default PublicTemplate;
