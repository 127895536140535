import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "~/components/Link";
import { LayoutModel, LayoutComponent } from "~/model/layout";
import Bg from "~/assets/vectors/login-background.svg";
import ACLogo from "~/assets/vectors/ac-logo.svg";

const useStyles = makeStyles({
	bgContainer: {
		position: "fixed",
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
		backgroundColor: "black",
		overflow: "hidden",
		zIndex: -1,
	},
	bg: {
		height: "100%",
		width: "100%",
		position: "absolute",
		left: "31.18%",
		right: "-45.83%",
		top: "6.56%",
		bottom: "-39.89%",
		shapeRendering: "geometricPrecision",
		"& *": {
			shapeRendering: "geometricPrecision",
		},
	},
	logoContainer: {
		position: "fixed",
		width: 96,
		height: 60,
		marginLeft: 28,
		marginTop: 42,
		color: "white",
	},
	logo: {
		height: "100%",
		width: "100%",
		fill: "white",
	},
});

const PublicLayoutContent: LayoutComponent = ({ children }) => {
	const styles = useStyles();

	return (
		<Fragment>
			<div className={styles.bgContainer}>
				<Bg className={styles.bg} />
			</div>
			<Link to={"/leads"} className={styles.logoContainer}>
				<ACLogo className={styles.logo} />
			</Link>
			{children}
		</Fragment>
	);
};

export const PublicLayout = new LayoutModel({
	render: PublicLayoutContent,
	hasToast: false,
	props: {},
});
