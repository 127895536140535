import React from "react";
import { createPublicView, ViewComponent } from "~/model/view/public";
import { LoginForm } from "~/composite/forms/LoginForm";
import PublicTemplate from "~/components/PublicTemplate";

const Login: ViewComponent = () => {
	return <PublicTemplate formComponent={<LoginForm />} />;
};

type LoginContext = {
	initialLoad: boolean;
};

const redirectTo = "/leads";

export default createPublicView<LoginContext>(Login, {
	title: "Login",
	redirectTo,
	redirectWithAuth({ user, loading, firstAuth, Redirect }, context) {
		if (firstAuth) context.initialLoad = true;
		if (loading) return null;

		if (context.initialLoad) {
			context.initialLoad = false;
			return null;
		}

		if (user) return <Redirect to={redirectTo} returnTo />;
		return null;
	},
});
